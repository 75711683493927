.app-container {
  font-family: "Nunito", sans-serif;
  background: #f8f9fc;
  min-height: 100vh;
  height: 100vh;
  padding: 0 !important;
  overflow-y: hidden;
}

.app-spinner {
	justify-content: center;
	height: 100%;
	align-items: center;
}

.app-spinner .spinner-border {
	height: 100px;
	width: 100px;
}

.app-topbar {
	background: white;
	height: 70px;
}

.topbar-container {
	padding: 0 100px;
	width: 100%;
}

.app-topbar img {
	height: 60px;
}

/********************* COMMON COMPONENTS **********************/
.common-button {
	background-color: #4e73df !important;
	border: 1px solid #4e73df !important;
}

.common-button:hover {
	background: #2e59d9 !important;
	border: 1px solid #2653d4 !important;
}

.common-form-control {
  border: none !important;
  background: #f8f9fc !important;
  color: #858796 !important;
}

.common-form-control::placeholder {
	color: #858796 !important;
}

.common-right-justified {
	justify-content: flex-end;
}

.common-center-justified {
	justify-content: center;
}

.teal-button {
	background-color: #36b9cc !important;
	border: 1px solid #36b9cc !important;
}

.teal-button:hover {
	background: #2c9faf !important;
	border: 1px solid #2a96a5 !important;
	color: black !important;
}

.delete-button {
	background-color: Tomato !important;
	border: 1px solid Tomato !important;
}

.delete-button:hover {
	background-color: FireBrick !important;
	border: 1px solid FireBrick !important;
	color: black !important;
}

.button-interior-row {
	align-items: center;
}

.button-interior-row svg {
	margin-right: 4px;
	font-size: 18px;
}

.common-checkbox {
	width: max-content !important;
}

.common-grey-spinner {
	color: #858796 !important;
}

.common-modal {
  color: #858796;
  background: rgba(0, 0, 0, 0.1);
  font-family: "Nunito", sans-serif;
}

.common-modal .modal-content {
	padding: 0 10px;
	border: none;
	border-radius: 2px;
}

.common-modal-header {
	font-size: 18px;
}

.common-admin-form-control {
	color: #858796 !important;
	margin: 0 !important;
}

.common-date-picker {
	border-radius: 3px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	width: 100%;
	padding-left: 6px;
}

.common-date-picker.invalid {
	border: 1px solid red;
}

.common-date-picker .react-date-picker__wrapper {
	border: none;
	border-radius: 2px;
}

.common-date-picker .react-date-picker__inputGroup input {
	color: #858796 !important;
}

.common-date-picker .react-date-picker__clear-button svg,
.common-date-picker .react-date-picker__calendar-button svg {
  stroke: #858796 !important;
}

.common-date-picker .react-date-picker__clear-button svg:hover,
.common-date-picker .react-date-picker__calendar-button svg:hover {
  stroke: black !important;
}

.form-saving-text .spinner-border {
	margin-right: 6px;
}

.search-bar {
	display: flex;
	align-items: center;
	margin-right: 16px;
	margin-bottom: 8px;
}

.search-label {
	margin: 0;
	margin-right: 8px;
}

.search-label.set-length {
	width: 62px;
}

.search-form-control {
	color: #858796 !important;
	height: 31px !important;
	width: 200px !important;
	font-size: 13px !important;
}

.search-form-control::placeholder {
	color: #858796 !important;
}

.extra-long-fc {
	width: 250px !important;
}

.centered-td {
	text-align: center;
}

.common-individual-comment {
	border: 1px solid rgba(0, 0, 0, 0.13);
	padding: 8px;
	margin-bottom: 8px;
	border-radius: 3px;
}

.common-individual-comment .common-comment-username {
	font-size: 12px;
	font-weight: bold;
}

.common-individual-comment .common-comment-date {
	font-size: 12px;
	font-style: italic;
	margin-bottom: 4px;
}

.common-individual-comment p {
	margin: 0;
}

.common-pagination .page-item a {
	border: 1px solid transparent;
	color: #36b9cc;
}

.common-pagination .page-item.active span {
	background: #36b9cc;
	border: 1px solid #36b9cc;
}

.common-pagination .page-item.active span:hover {
	cursor: pointer;
}

.pagination__list li {
	list-style-type: none !important;
}

.pagination-row {
	justify-content: flex-end;
	margin-top: 16px;
}

.scrollable-tab {
	/* overflow-x: scroll; */
	max-height: 400px !important;
	overflow-y: scroll;
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 0 !important;
}

.admin-tabs .nav-item {
	color: #858796;
	font-size: 14px;
	padding: 4px 8px;
}

.admin-tab {
	padding-top: 4px;
}

.admin-tabs .active {
	color: #858796 !important;
	background: transparent !important;
	border-bottom: 0 !important;
}

.tab-icon {
	margin-right: 4px;
}

.tab-title-div {
	/* width: 150px; */
	align-items: center;
}

.table-header-cell {
	min-width: 115px !important;
}

#button-tooltip .tooltip-inner {
	width: max-content !important;
	max-width: max-content !important;
}

.topbar-search-button {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.topbar-search-bar {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.common-grey-spinner {
	color: #858796 !important;
}

.common-spinner-row {
	justify-content: center;
	padding: 4px;
}

.common-formFile {
	width: 100%;
	border: 1px solid #ced4da;
	border-radius: 3px;
	overflow-x: hidden;
}

.interior-loading-div {
	height: 700px;
}

.pagination {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
}

.pagination ul {
	list-style-type: none;
}

.pagination__list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.pagination__item {
	cursor: pointer;
	align-items: center;
	display: flex;
	text-align: center;
	height: 32px !important;
	width: 32px !important;
	color: #36b9cc;
}

.pagination__item:hover {
	text-decoration: underline;
}

.pagination__link {
	border-radius: 3px;
	width: 32px;
	height: 32px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.pagination__link--active {
	background: #36b9cc;
	color: white !important;
}

.pagination__arrow {
	position: relative;
	width: 32px;
	height: 32px;
	color: #36b9cc !important;
}

.pagination__arrow.disabled {
	opacity: 0.5;
	cursor: default;
}

.pagination__icon {
	display: inline-block;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
}

.pagination__icon::after {
  content: "";
  position: absolute;
  top: 12px;
  height: 10px;
  width: 10px;
}

.pagination__icon--prev::after {
	/* left: 15px; */
	/* transform: rotate(135deg); */
}

.pagination__icon--next::after {
	/* left: 9px;
  transform: rotate(-45deg); */
}

.pagination__ellipsis {
	position: relative;
	width: 32px;
	height: 32px;
	color: #36b9cc;
}

.pagination__ellipsis-link {
	font-size: 20px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	pointer-events: none;
	user-select: none;
}

.clickable-td {
	width: max-content;
	cursor: pointer;
}

.clickable-td:hover {
	color: blue;
	text-decoration: underline;
}
/********************* END OF COMMON COMPONENTS ***************/

/********************* LOGIN COMPONENT ************************/

.login-container {
  background-color: #f8f9fc;
  /* align-items: center; */
  height: 100% !important;
}

.login-row {
	height: 100%;
	/* height: 100vh; */
	justify-content: center;
	align-items: center;
}

.login-logo-row {
  background: #5a5c69;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  /* height: 90px; */
}

.login-logo {
	max-height: 8rem;
}

.login-logo-box {
	padding: 16px 0 16px 70px;
}

.register-box {
	background: white;
	height: 400px;
	width: 600px;
	border: 1px solid rgba(0, 0, 0, 0.08);
	border-radius: 2px;
	padding: 20px;
	position: relative;
}

.login-box {
	box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
	border: none !important;
}

.login-box .row {
	height: 100%;
}

.login-content-col {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.login-content-col h4 {
	color: #858796;
	height: max-content;
}

.login-content-col button {
	margin-top: 20px;
}

.login-content-col .row {
	justify-content: space-around;
	height: max-content;
}

.login-navlinks {
	/* position: absolute; */
	width: 100%;
	margin-top: 32px;
	justify-content: center;
	display: flex;
}

.login-navlinks a {
	color: #4e73df;
}

.login-navlinks a:hover {
	color: #2e59d9;
}

.login-formcontrol-row {
	justify-content: space-between !important;
	margin-bottom: 10px;
	color: #858796;
	align-items: center;
}

.login-formcontrol-row .form-control {
	width: 70%;
}

.login-header {
  padding: 3rem !important;
  background: #2ac2e9;
}

.login-box-padding {
	padding: 0 3rem 0 3rem;
}

.login-welcome .col {
	padding: 15px;
}

.login-box-col {
	padding: 15px !important;
}

.login-welcome h1 {
	font-weight: 400 !important;
	margin: 0;
}

.login-welcome p {
	margin: 0;
}

.login-formcontrol-box {
	width: 100%;
	border: 1px solid #e3e6f0;
	border-radius: 0.35rem;
}

.login-formcontrol-text {
	margin: 11px 7px 0 15px;
	font-size: 13px;
}

.login-formcontrol {
	width: 100% !important;
	border: none !important;
	color: #858796 !important;
}

.login-formcontrol-container {
	margin-bottom: 16px;
}

.login-button-row {
	justify-content: flex-end !important;
}

.keep-me-logged-in {
	color: #858796;
	/* margin-top: 16px; */
	font-size: 14px;
}

/********************* END OF LOGIN COMPONENT *****************/

/********************* REGISTER COMPONENT *********************/
.register-navlink {
	justify-content: center;
	display: flex;
	position: absolute;
	bottom: 0;
	width: 100%;
	color: #858796;
}

.register-navlink a {
	color: #4e73df;
	margin-left: 10px;
}

.register-navlink a:hover {
	color: #2e59d9;
}

/********************* END OF REGISTER COMPONENT **************/

/********************* FORGOT COMPONENT *********************/
.forgot-header {
	color: #858796;
	margin-bottom: 40px;
	padding: 0 60px;
	text-align: center;
}

.request-success-col {
	color: #858796;
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.request-success-col .row {
	height: 100% !important;
	align-items: center;
}

/********************* END OF FORGOT COMPONENT **************/

/********************* CONFIRM EMAIL COMPONENT *********************/
.confirmation-body {
	text-align: center;
}

.confirmation-footer {
	text-align: center;
	position: absolute;
	bottom: 20px;
}

.resend-confirmation {
	text-decoration: underline;
	color: #4e73df;
}

.resend-confirmation:hover {
	cursor: pointer;
	color: #2e59d9;
}
/********************* END OF CONFIRM EMAIL COMPONENT **************/

/********************* ADMIN COMPONENT *********************/

.admin-datepicker {
	color: #858796 !important;
	height: 31px !important;
	width: 200px !important;
	font-size: 13px !important;
	border: 1px solid #ced4da;
	border-radius: 4px;
	padding: 6px 12px;
}

.admin-datepicker::placeholder {
	color: #858796 !important;
}

.admin-container {
	height: 100%;
	padding: 0 !important;
}

.admin-col {
	/* background: red; */
}

.admin-container .row {
	height: 100%;
}
@media (max-width: 576px) {
	.admin-content-pane {
		max-width: calc(100vw - 6.5rem) !important;
	}
	.admin-sidebar {
		width: 6.5rem !important;
	}

	.admin-sidebar .admin-logo {
		height: 40px;
	}

	.admin-sidebar .admin-list-item-title {
		font-size: 12px;
		text-align: center;
	}

	.admin-sidebar .admin-list-item-title .row {
		justify-content: center;
	}

	.admin-sidebar .admin-list-accordion .row {
		justify-content: center;
		font-size: 12px;
	}

	.admin-sidebar .admin-list-item-icon {
		font-size: 16px !important;
	}
	.admin-sidebar-collapse-toggle-row {
		display: none !important;
	}
	.admin-sidebar .admin-list-accordion-title {
		width: 100%;
		word-break: normal;
		/* word-break: break-all !important; */
		/* text-overflow: clip; */
		/* text-overflow: ellipsis; */
	}

	.admin-sidebar .admin-list-accordion-collapse {
		position: absolute !important;
		left: 100px;
		background: black !important;
		width: max-content;
		transform: translate(100px, 0px) !important;
	}

	.admin-sidebar .dropdown-toggle::after {
		display: none;
	}

	.admin-sidebar .admin-list-accordion-collapse ul li {
		padding: 8px 16px;
	}

	.admin-sidebar .admin-list-accordion-collapse ul li:hover {
		background: rgba(255, 255, 255, 0.3);
		border-radius: 3px;
	}
}

.admin-sidebar#collapsed-sidebar {
	width: 6.5rem !important;
}

.admin-sidebar#collapsed-sidebar .admin-logo {
  /* height: 40px; */
  /* width: 100% */
}

.admin-sidebar#collapsed-sidebar .admin-list-item-title {
	font-size: 12px;
	text-align: center;
	background: red;
}

.admin-sidebar#collapsed-sidebar .admin-list-item-title .row {
	justify-content: center;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion .row {
	justify-content: center;
	font-size: 12px;
}

.admin-sidebar#collapsed-sidebar .admin-list-item-direction-arrow {
	display: none;
}

.admin-sidebar#collapsed-sidebar .admin-list-item-icon {
	font-size: 16px !important;
}

.admin-sidebar-collapse-toggle-row {
	justify-content: center;
	margin-top: 16px;
}

.admin-sidebar-collapse-toggle-aura {
	background: rgba(255, 255, 255, 0.1);
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
}

.admin-sidebar-collapse-toggle-aura:hover {
	cursor: pointer;
	background: rgba(255, 255, 255, 0.2);
}

.admin-sidebar-bottom {
	position: absolute;
	bottom: 16px;
	align-items: center;
	width: 224px;
	justify-content: center;
	display: flex;
}

.admin-sidebar-bottom .admin-sidebar-logout-icon {
	margin-right: 6px;
	color: #858796;
}

.admin-sidebar-bottom:hover {
	cursor: pointer;
}

.admin-sidebar-bottom:hover .admin-sidebar-logout-icon {
	color: white;
}

.admin-sidebar {
  background: #0d0d0d;
  width: 224px;
  color: white;
  position: relative;
  z-index: 4 !important;
}

.admin-sidebar .col {
	padding: 16px;
}

.admin-sidebar .col {
	padding: 16px;
}

.admin-sidebar-logout {
	justify-content: center;
	align-items: center;
	display: flex;
	position: absolute;
	width: 250px;
	bottom: 16px;
}

.click-to-logout svg {
	margin-right: 4px;
}

.click-to-logout:hover {
	color: #2e59d9;
	cursor: pointer;
}

.admin-logo {
  width: 100%;
  /* max-height: 50px; */
  /* height: 60px; */
}

.admin-logo-row {
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	margin-bottom: 16px;
	justify-content: center;
	padding-bottom: 16px;
}

.system-header {
	color: rgba(255, 255, 255, 0.4);
	font-size: 0.65rem;
	font-weight: 800;
}

.admin-topbar-navdropdown {
	position: absolute !important;
	top: 0;
	left: 0;
	height: 70px;
	width: 200px;
	border-top: 8px solid DarkTurquoise;
	box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.admin-topbar-navdropdown a {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #858796;
}

.admin-topbar-navdropdown a:hover {
	color: black;
}

.admin-topbar-navdropdown .dropdown-toggle::after {
	color: DarkTurquoise !important;
}

.admin-topbar-navdropdown .dropdown-menu {
	border: none;
	padding: 0;
	margin: 0;
	width: 200px;
	border-radius: 0;
	box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.admin-topbar-navdropitem {
	height: 70px !important;
	width: 200px !important;
}

.admin-topbar-navdropitem {
	border-top: 8px solid #858796 !important;
}

.admin-topbar-navdropitem:first-child {
	border-top: 8px solid BlueViolet !important;
}

.admin-topbar {
	background: white;
	box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
	height: 4.375rem;
	padding: 8px 24px;
	z-index: 100;
	position: relative;
}

.admin-topbar-nav {
	font-size: 13px;
	position: absolute !important;
	top: 0 !important;
	right: 24px;
	height: 70px;
	padding: 0 12px;
}

.admin-topbar-nav a {
}

.admin-topbar-nav:hover {
	color: #b7b9cc !important;
}

.admin-topbar-nav .dropdown-toggle {
	padding: 0;
}

.admin-topbar-nav .dropdown-menu {
	box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
	border: 1px solid rgba(0, 0, 0, 0.05);
	font-size: 14px;
}

.admin-topbar-nav .dropdown-item {
	color: black !important;
}

.admin-topbar-nav .dropdown-item svg {
	color: #d1d3e2;
	margin: 0;
	margin-right: 10px;
}

.admin-topbar-nav .dropdown-toggle::after {
	color: transparent;
}

.admin-topbar-nav a {
	color: #858796;
	align-items: center;
	display: flex;
	height: 100%;
}

.admin-topbar-nav a:hover {
	color: #b7b9cc;
}

.admin-topbar-nav .dropdown-item:active {
	background: rgba(0, 0, 0, 0.1);
}

.admin-topbar-nav a svg {
	margin-left: 8px;
	font-size: 14px;
}

.admin-topbar-nav a::after {
	margin-left: 8px;
}

.admin-topbar-row {
	justify-content: space-between;
	align-items: center;
}

.admin-topbar .form-control {
	width: 300px;
}

.admin-search-container {
  display: flex;
  width: 50%;
  align-items: center;
  margin-left: 1rem;
}

.admin-list-group .active {
	/* background: rgba(255, 255, 255, 0.4) !important; */
}

.admin-list-item {
	font-size: 0.85rem;
	color: white;
	background: transparent !important;
	/* padding: 16px; */
	padding: 16px 0 !important;
	border: none !important;
}

.admin-list-item:hover {
	cursor: pointer;
}

.admin-list-item:hover .admin-list-item-icon {
	color: white;
}

.admin-list-item .row {
	align-items: center;
}

.admin-list-item-icon {
	color: rgba(255, 255, 255, 0.4);
	margin-right: 5px;
}

.admin-list-item-title {
	color: white;
	border: none;
}

.admin-list-item-title:hover {
	color: white;
	text-decoration: none;
}

.admin-list-accordion button {
	background: transparent !important;
	color: white !important;
	border: none;
	padding: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: 14px;
	text-align: center;
}

.admin-list-accordion-collapse {
	background: rgba(255, 255, 255, 0.3) !important;
	border-radius: 3px;
	padding: 8px 0;
	margin-top: 16px;
	position: relative !important;
	width: 100%;
	font-size: 14px !important;
	/* margin-top: 8px; */
	margin-bottom: 8px !important;
}

.admin-list-accordion-collapse ul {
	list-style: none;
	padding: 0;
	margin: 0 8px;
}

.admin-list-accordion-collapse ul li {
	padding: 8px 16px;
}

.admin-list-accordion-collapse ul li:hover {
	background: rgba(0, 0, 0, 0.3);
	border-radius: 3px;
}

.admin-content-pane {
	height: 100vh;
	max-width: calc(100vw - 224px);
	padding: 16px 0;
	overflow-y: scroll;
	z-index: 2 !important;
	position: relative;
	color: #858796;
}

.admin-content-pane#collapsed-sidebar {
	max-width: calc(100vw - 6.5rem);
}

/********************* END OF ADMIN COMPONENT **************/

/********************* DASHBOARD COMPONENT *********************/
.dashboard-container {
	justify-content: center;
	display: flex;
	align-items: center;
	height: 100%;
}

.dashboard-box {
	justify-content: center;
	display: flex;
	align-items: center;
	font-size: 18px;
}

/********************* END OF DASHBOARD COMPONENT **************/

/********************* FORMS COMPONENT *********************/
.forms-container {
	height: 100vh;
	overflow-y: scroll;
	z-index: 50;
	position: relative;
	justify-content: center;
	display: flex;
	color: #858796;
	padding: 24px;
}

.forms-header {
	height: max-content !important;
	color: #858796;
	margin-bottom: 24px;
	align-items: center;
	justify-content: space-between;
}

.forms-header.clickable:hover {
	cursor: pointer;
	color: black;
}

.forms-search .col-sm-4 {
	margin-right: 20px;
}

.form-search-container {
	display: flex;
	align-items: center;
	color: #858796;
}

.form-search-container .form-control {
	margin-left: 10px;
	background: white;
	border: 1px solid #d1d3e2;
	color: #858796 !important;
}

.form-dropdown {
	color: #858796 !important;
}

.forms-table-box {
	background: white;
	font-size: 13px;
	padding: 20px;
	position: relative;
	margin-bottom: 16px;
}

.forms-search {
	margin-bottom: 16px;
}

.forms-create-button-row {
	justify-content: center;
	padding-top: 16px;
}

.forms-table-row {
}

.forms-table {
}

.forms-table tr {
	color: #858796;
}

.forms-table th {
	width: 50%;
}

.individual-form-status-row {
	justify-content: space-between;
}

.individual-form-icon:not(:last-child) {
	margin-right: 10px;
}

.individual-form-icon {
	font-size: 16px;
	color: #858796;
}

.individual-form-icon:hover {
	color: black;
	cursor: pointer;
}

.individual-form-icon-disabled {
	font-size: 16px;
	color: rgba(0, 0, 0, 0.2);
}

.edit-forms-header h4 {
	align-items: center;
	display: flex;
}

.edit-forms-header:hover {
	cursor: pointer;
	color: black;
}

.edit-form-name {
	padding-bottom: 16px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	justify-content: space-between;
}

.edit-form-name div {
	display: flex;
	align-items: center;
}

.edit-form-name .form-control {
	width: 300px;
}

.add-fields-button-row {
	justify-content: center;
	padding-top: 16px;
}

.individual-field {
	align-items: center;
	margin-top: 16px;
}

.individual-field .form-control {
	width: 75%;
	color: #858796;
}

.individual-field .form-control:focus {
	color: #858796;
}

.individual-field-required-row {
	justify-content: space-between;
}

.individual-field-required-row div {
	display: flex;
	align-items: center;
}

.individual-field-column {
	margin-right: 10px;
}

.individual-field-column .row {
	align-items: center;
	color: #858796;
	justify-content: space-around;
}

.no-fields {
	color: #858796;
	justify-content: center;
	display: flex;
	padding: 20px;
}

.form-publish-button-column {
	justify-content: flex-end;
}

.form-publish-button-column .teal-button {
	margin-left: 16px;
}

.form-publish-button-column .spinner-border {
	margin-right: 6px;
	color: #858796 !important;
}

.no-forms-table {
}

.no-forms-error {
	color: red;
}

.individual-dropdown-option {
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
}

.individual-dropdown-option .form-control {
	width: 80%;
	color: #858796 !important;
}

.individual-dropdown-option .form-control::placeholder {
	color: rgba(0, 0, 0, 0.3);
}

.form-search-container .form-control::placeholder {
	color: rgba(0, 0, 0, 0.3);
}

/********************* END OF FORMS COMPONENT **************/

/********************* ROLES COMPONENT **********************/
.roles-search-bar {
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	padding-bottom: 16px;
	margin-bottom: 16px;
}

.roles-search-bar-container .form-control {
  margin-left: 8px !important;
}

.roles-search-bar button {
	margin-left: 10px;
}

.roles-search-bar div {
	align-items: center;
	display: flex;
}
.roles-search-bar .form-control {
	width: 300px;
	margin-left: 10px;
}

.editing-role-section {
}

.editing-role-form-row {
	justify-content: space-between;
	height: max-content !important;
	margin-bottom: 16px;
}

.editing-role-form-row .form-control {
	margin-left: 10px;
}

.editing-role-form-row .col {
	display: flex;
}

.editing-role-form-col .row {
	width: 100%;
	margin-right: 16px;
}

.editing-role-form-col {
}

.roles-info-icon {
	margin-right: 6px;
}
/********************* END OF ROLES COMPONENT ***************/

.roles-drag-and-drop-col {
  background: #f8f9fc;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 16px !important;
  width: 510px !important;
}

.roles-drag-drop-row h5 {
	margin-bottom: 16px;
}

.roles-draggable {
	background: white;
	height: 40px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 8px;
	align-items: center;
}

.roles-draggable:not(:last-child) {
	margin-bottom: 8px;
}

.roles-draggable#hidden {
	display: none;
}

.roles-draggable.disabled {
  background: #e9ecef;
}

.roles-drag-and-drop-context-col {
	height: max-content;
}

.roles-publish-button-row {
	margin-top: 16px;
	justify-content: center;
}

.roles-draggable#drag-disabled {
	background: red;
}

/************* REGISTRATION *************************************/
.registration-icon {
	margin-right: 8px;
	font-size: 18px;
}

.registration-icon.pending {
	color: goldenRod;
	border-radius: 100px;
}

.registration-checklist-item-row:hover .registration-icon.pending {
	color: gold;
}

.registration-icon.confirmed {
	color: MediumSeaGreen;
}

.registration-checklist-item-row:hover .registration-icon.confirmed {
	color: MediumSpringGreen;
}

.progress-bar-row {
	padding: 8px 0;
}

.registration-progress-bar {
  width: 100%;
  background-color: #323232 !important;
  border-radius: 2px !important;
}

.registration-progress-bar .progress-bar {
	background: #4e73df;
}

.registration-form-header {
	justify-content: center;
	margin-bottom: 40px;
	height: max-content !important;
}

.registration-form-header h4 {
	height: max-content;
}

.registration-body-text {
	justify-content: center;
}

.registration-body-text-footer {
	position: absolute;
	bottom: 0;
	height: max-content !important;
	justify-content: center;
	width: 100%;
}

.registration-body-text-footer .resend-confirmation {
	margin-left: 5px;
}

.registration-column {
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
}

.registration-column-confirmation {
	align-items: center;
	display: flex;
	flex-direction: row;
	text-align: center;
}

.registration-item-box {
	background: white;
	font-size: 13px;
	padding: 20px;
}

.registration-confirm-email {
	background: white;
	font-size: 13px;
	padding: 20px;
	height: 300px;
	width: 625px;
}

.registration-row {
}

.registration-confirm-email-container {
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	margin-top: -70px;
}

.registration-list-item {
	font-size: 0.85rem;
	color: white;
	background: transparent !important;
	padding: 8px 0 !important;
	border: none !important;
	align-items: center !important;
	height: 36px !important;
}

.registration-list-item.active {
	margin-top: 0 !important;
}

.registration-list-item:hover {
	cursor: pointer;
}

.registration-checklist-item-row {
	align-items: center;
}

.registration-list-item.active
  .registration-checklist-item-row
  .registration-icon.pending {
  color: gold !important;
}

.registration-list-item.active
  .registration-checklist-item-row
  .registration-icon.confirmed {
  color: MediumSpringGreen !important;
}

.registration-individual-field {
	/* border-bottom: 1px solid rgba(0, 0, 0, 0.08); */
	height: max-content !important;
}

.registration-individual-field .col {
	padding: 0;
}

.registration-individual-field:not(:last-child) {
	margin-bottom: 40px;
}

.registration-form-row-header {
	margin-bottom: 40px;
	height: max-content !important;
	justify-content: space-between;
	align-items: center;
}

.registration-header {
}

.registration-form-file .form-control-file {
	background: red !important;
	color: red !important;
}

.registration-submit-button-row {
	height: max-content !important;
	justify-content: center;
}

.registration-form-group {
	width: 100%;
}

.registration-form-control-column {
	display: flex;
	flex-direction: column;
}

.registration-form-file-control {
	width: 100%;
}

.registration-form-file-control label {
	color: rgba(0, 0, 0, 0.3);
}

.registration-form-control-column .rbt-input-main {
  margin: 0 !important;
  height: 33px;
  border: none;
  font-size: 14px;
}

.registration-form-control-column .rbt-input {
  color: #858796 !important;
}

.registration-form-control-column .rbt-menu a {
  color: #858796 !important;
}

.registration-form-control-column .rbt-close {
	font-weight: normal;
	font-size: 20px;
}

.registration-display-image-cancel-icon {
	font-weight: normal;
	font-size: 20px;
}

.registration-form-control-typeahead {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.registration-form-typeahead-row {
	align-items: center !important;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	padding-right: 6px;
}

.registration-form-typeahead-row-invalid {
	align-items: center !important;
	border: 1px solid red;
	border-radius: 4px;
	padding-right: 6px;
}

.login-role-select-div {
	align-items: center;
	display: flex;
}

.register-role-description-icon {
	margin-left: 6px;
}

.registration-display-image-container {
	width: 100%;
	position: relative;
}

.registration-display-image {
	max-height: 300px;
	width: 100%;
	object-fit: contain;
}

.registration-display-image-cancel-icon {
	position: absolute;
	font-size: 16px;
	right: 0;
	color: #858796;
}

.registration-display-image-cancel-icon:hover {
	color: black;
	cursor: pointer;
}
/************* END OF REGISTRATION ******************************/

/************* SUBMISSIONS **************************************/
.submission-form-control {
	width: 75%;
}

.submission-form-control::placeholder {
	color: rgba(0, 0, 0, 0.3) !important;
}

.submission-user-selector {
	padding: 0px 18px;
	justify-content: center;
	text-align: center;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	margin-bottom: 16px;
}

.submission-user-selector:not(:last-child) {
	margin-right: 8px;
}

.submission-user-selector:hover {
	background-color: #36b9cc;
	border: 1px solid #36b9cc;
	color: black;
	cursor: pointer;
}

.submission-user-selector.selected {
	background-color: #36b9cc;
	border: 1px solid #36b9cc;
	color: white;
}

.submission-user-selector.selected:hover {
	background: #2c9faf !important;
	border: 1px solid #2a96a5 !important;
	color: black !important;
}

.submissions-search-bar {
	margin-bottom: 16px;
	padding-bottom: 16px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	align-items: center;
}

.submissions-search-bar label {
	margin: 0;
}

.submissions-search-bar .col:not(:last-child) {
	margin-right: 8px;
}

.submission-form-control {
	color: #858796 !important;
}

.submission-loading-users {
	width: 100%;
	justify-content: center;
}

.admin-content-container .table-responsive {
	/* max-width: 900px; */
	width: 100%;
}

.submissions-table.no-margin {
	margin: 0 !important;
}

.submissions-table-container {
	width: 100%;
}

.submissions-table {
	/* table-layout: fixed; */
}

.submissions-table-box {
	background: white;
	font-size: 13px;
	padding: 20px;
}

.submissions-search {
	margin-bottom: 16px;
}

.submissions-create-button-row {
	justify-content: center;
	padding-top: 16px;
}

.submissions-table-row {
}

.submissions-table {
	width: 100%;
	/* max-height: 1200px !important; */
}

.submissions-table tr {
	color: #858796;
}

.submissions-table th {
	/* min-width: max-content !important; */
}

.risk-control-table th {
	min-width: max-content !important;
}

.specific-submission-field {
	justify-content: space-between;
	align-items: center;
}

.specific-submission-field-label {
	margin-bottom: 16px;
	font-size: 14px;
}

.specific-submission-content {
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 2px 6px;
	border-radius: 3px;
	font-size: 16px;
	width: 100% !important;
	max-width: 100% !important;
	font-size: 14px;
	background: rgba(0, 0, 0, 0.03);
}

.specific-submission-field:not(:last-child) {
	margin-bottom: 24px;
}

.submission-modal-column {
	margin: 0 !important;
	padding: 0 !important;
}

.submission-modal-column .row {
	align-items: center;
}

.submission-modal-column .row label {
	margin: 0;
	margin-right: 8px;
}

.submission-modal-column .row .col {
	margin-right: 8px;
}

.submission-modal .modal-dialog {
	max-width: 800px;
}

.specific-submission-image {
	max-height: 300px;
	width: 100%;
	object-fit: contain;
}

.submission-modal-submitted-by {
	font-size: 14px;
}

.submission-modal-submitted-by b {
	margin: 0 4px;
}

.submissions-reset-queries-button {
	position: absolute;
	right: 4px;
	top: 4px;
}

.submissions-reset-queries-button:hover {
	color: black;
	cursor: pointer;
}

.submissions-search-col {
	margin-right: 16px;
	display: flex;
	align-items: center;
}

.submission-form-control-label {
	margin-right: 4px !important;
}

/************* END OF SUBMISSIONS *******************************/

/************* PREREGISTRATION **********************************/
.preregistration-container {
  padding: 16px 32px !important;
  background: #f8f9fc;
}

.preregistration-starter-box {
	height: 100%;
	border: 1px solid rgba(0, 0, 0, 0.08);
	border-radius: 2px;
	padding: 20px 128px;
	background: white;
	/* margin-bottom: 64px; */
	/* padding-top: 64px; */
	/* margin-bottom: 64px; */
}

.preregistration-starter-box-row {
	height: 100%;
}

.preregistration-form {
	width: 100%;
}

.preregistration-content-col {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.preregistration-content-col h4 {
	color: #858796;
	height: max-content;
}

.prereg-header {
	justify-content: space-around;
	height: max-content;
}

.prereg-form-group {
	color: #858796;
	font-size: 14px;
	padding-bottom: 16px;
	/* border-bottom: 1px solid red; */
}

.prereg-form-row {
	align-items: center;
}

.prereg-row {
	padding-left: 16px;
	/* padding-bottom: 20px; */
}

.prereg-form-list {
	margin-top: 8px;
}

.prereg-form-list li:not(:last-child) {
	margin-bottom: 4px;
}

.preregistration-form.bottom-form {
	/* padding-bottom: 64px !important; */
	/* margin-bottom: 64px !important; */
}

.prereg-navlink {
	justify-content: center;
	display: flex;
	position: absolute;
	bottom: -6px;
	width: 100%;
	color: #858796;
}

.prereg-navlink a {
	color: #4e73df;
	margin-left: 10px;
}

.prereg-navlink a:hover {
	color: #2e59d9;
}

.prereg-captcha-row {
	justify-content: center;
	margin-bottom: 16px;
}

.prereg-next-button-row {
	justify-content: center;
	margin-bottom: 32px;
}

.cannot-register-row {
	color: #858796;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.cannot-register-navlink {
	justify-content: center;
	position: absolute;
	width: 100%;
	color: #858796;
	display: flex;
	bottom: 0;
}

.cannot-register-navlink a {
	color: #4e73df;
	margin-left: 10px;
}

.cannot-register-navlink a:hover {
	color: #2e59d9;
}

.cannot-register-col {
	height: 100%;
	padding: 0 !important;
}
/************* END OF PREREGISTRATION *****************************/

/************* RISK SETTINGS ***************************************/
.risk-settings-content-row {
	font-size: 14px;
}

.risk-settings-key-checkbox {
	width: 50%;
}

.max-amount-of-options {
	margin-bottom: 16px;
	font-size: 14px;
}

.audit-changes-icon {
	margin-top: 16px;
	margin-left: 2px;
}

.audit-changes-icon:hover {
	cursor: pointer;
	color: black;
}

.table-cell {
}

.table-cell-row {
	justify-content: flex-end;
	align-items: center;
}

.table-cell-row-editing {
	justify-content: space-between;
}

.matching-table-item .individual-form-icon {
	color: transparent;
}

.matching-table-item:hover {
	background: rgba(0, 0, 0, 0.02);
}

.matching-table-item:hover .individual-form-icon {
	color: #858796;
}

.matching-table-item:hover .individual-form-icon:hover {
	color: black;
}

.table-cell-formControl {
	max-width: 50% !important;
	color: #858796 !important;
	border: none !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
	border-radius: 0 !important;
	padding: 0 !important;
	height: max-content !important;
	font-size: 13px !important;
}

.risk-loading-row {
	background: red;
	width: 100%;
}
/************** END OF RISK SETTINGS *******************************/

.full-height {
	height: 100vh;
	max-height: 100vh;
	width: 100%;
	/* overflow-y: scroll; */
}

/**************** HISTORY AUDIT **************************************/
.history-query-header {
	margin: 16px 0 !important;
	font-size: 14px;
	display: block;
}

.history-query-header b {
	margin: 0 4px;
}

.search-bar-row {
	margin-bottom: 16px;
}

/**************** END OF HISTORY AUDIT *****************************/

/**************** RISK ALERTS ********************************************/
.error-details-td .row {
	align-items: center;
}

.error-details-td .col-sm-1 {
	justify-content: center;
	display: flex;
}

.react-quill-container {
	padding-top: 16px;
	padding-bottom: 30px;
}

.react-quill-container .teal-button {
	position: absolute;
	bottom: 8px;
	right: 16px;
}

.react-quill-container label {
	font-size: 14px;
}

.common-react-quill {
	width: 100%;
	height: 100%;
}

.risk-alerts-modal-reset-icon {
	position: absolute;
	right: 50px;
	top: 21px;
}

.risk-alerts-comments-section-col {
	max-height: 400px;
	overflow-y: scroll;
}

/**************** END OF RISK ALERTS *************************************/

/*************************** CASE MANAGEMENT ******************************/
.create-a-case {
	position: absolute;
	top: -4px;
	right: 0;
}

.case-search {
	width: 465px !important;
}

.case-reset-params-button {
	position: absolute;
	top: 16px;
	right: 16px;
	margin: 0 !important;
}

.specific-case-form-row {
	margin-bottom: 8px;
}

.specific-case-form-row .form-data-col {
	min-height: 40px;
}

.specific-case-form-row .form-data-col:hover {
	cursor: default;
}

.specific-case-form-row:hover .individual-form-icon {
	display: inline;
}

.specific-case-form-row .individual-form-icon {
	margin-left: 8px;
	display: none;
}

.sc-edit-formControl {
	color: #858796 !important;
	border: none !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
	border-radius: 0 !important;
	padding: 0 !important;
	font-size: 13px !important;
}

.sc-edit-formControl.normal {
	height: max-content !important;
}

.sc-edit-formControl.textarea {
	min-height: 100px !important;
	max-height: 300px !important;
}

.sc-header {
	margin-bottom: 16px;
}

.sc-tab-button {
	position: absolute;
	top: -4px;
	right: 0;
}

.sc-tab-reload {
	position: absolute;
	top: 3px;
	right: 130px;
}

.sc-alerts-search-bar .search-bar {
	margin-bottom: 4px;
}

.sc-alerts-search-bar .search-label {
	font-size: 14px;
	width: 100px;
}

.sc-modal .modal-content {
}

.order-details-modal .modal-content {
	/* height: 500px; */
}

.max-width-modal .modal-content {
	width: max-content !important;
	/* width: 620px !important; */
	/* min-width: 614px; */
	/* height: 1017px; */
}

.max-width-modal .modal-body {
	/* max-height: 1017px; */
	/* width: 300px; */
}

.sc-table {
	font-size: 13px;
	max-height: 300px !important;
	height: 300px;
	overflow-y: scroll !important;
}

.sc-checkbox-td {
	justify-content: center !important;
	align-items: center;
}

.sc-checkbox {
}

.sc-checkbox .form-check-input {
	/* background: red !important; */
	margin: 0;
	margin-top: 2px;
}

.sc-search-bar {
	/* margin-bottom: 4px; */
}

.sc-search-bar .search-bar {
	margin-bottom: 8px;
}

.sc-search-bar .search-label {
	width: 100px;
}

.activity-individual-submission {
	padding: 4px 8px;
	border: 1px solid rgba(0, 0, 0, 0.13);
	margin-bottom: 8px;
	border-radius: 3px;
}

.activity-individual-submission label {
	font-size: 12px;
	margin: 0;
}

.activity-individual-submission .message {
	font-size: 15px;
	margin-bottom: 4px;
}

.activity-individual-submission .comment {
	font-size: 14px;
}

.sc-comment-box {
	width: 100% !important;
	min-height: 55px !important;
	margin-right: 16px;
	margin-bottom: 8px;
}

.sc-comment-box-row {
	margin-bottom: 8px;
}

.right-justified-row {
	height: max-content !important;
	justify-content: flex-end;
}

.identity-mind-tag {
	width: 100%;
	/* margin-bottom: 16px; */
}

.identity-mind-tag .card-header {
	padding: 0;
	border: none;
}

.identity-mind-toggle {
	width: 100%;
	padding: 0 !important;
	height: 100%;
	color: #36b9cc !important;
	background-color: transparent !important;
	text-decoration: none !important;
	border-radius: 0 !important;
}

.identity-mind-toggle:hover {
	background: #2c9faf !important;
	border: 1px solid #2a96a5 !important;
	color: black !important;
	text-decoration: none !important;
	border: 1px solid #36b9cc !important;
}

.identity-mind-tag .card-body {
	padding: 8px;
}

.reduce-padding {
	padding: 8px 0 !important;
}

.pre-trade-tabs {
	/* border: none !important; */
	margin-bottom: 16px !important;
}

.pre-trade-tabs .nav-item {
	color: #858796;
	font-size: 14px;
	padding: 4px 8px;
	border: none !important;
}

.pre-trade-tabs .nav-item:hover {
	color: black;
}

.pre-trade-tab {
	padding-top: 4px;
}

.pre-trade-tabs .active {
	color: #858796 !important;
	font-weight: bold;
	background: transparent !important;
	border-bottom: 0 !important;
}

.cases-totals-col {
	margin-right: 16px !important;
}

.cases-totals-row {
	height: max-content !important;
	min-height: 300px;
	position: relative;
	/* background: white; */
}

.cases-totals-charts {
	height: inherit !important;
}

.cases-totals-container {
	padding: 8px;
}

.floating-chart-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 220px;
}

.cases-totals-container-charts {
	width: 100%;
	margin-left: 236px;
	padding-bottom: 16px;
	z-index: 1000;
}

.cases-totals-charts-col {
	/* margin-bottom: -16px !important; */
}

.cases-totals-row .col {
	/* background: red; */
}

.cases-graph-box {
	/* overflow: hidden;
  margin: 0;
  width: auto;
  min-height: 300px; */
}

.pda-graph-box {
	width: auto;
	height: 300px;
	background: white;
	/* overflow: hidden; */
}

.cases-graph-box .highcharts-container {
	/* background: red !important; */
}

.value-icon {
	font-size: 22px;
	margin-right: 4px;
}

.value-row {
	align-items: center;
	justify-content: space-between;
}

.severity-td {
	font-weight: bold;
	text-align: right;
}

.status-td {
	text-align: right;
}

.small-td {
	width: max-content;
}

.severity-td {
	max-width: 100px;
}

.severity-td#low {
	color: LimeGreen;
}

.severity-td#medium {
	color: GoldenRod;
}

.severity-td#high {
	color: Crimson;
}

.value-row #positive {
	color: LimeGreen;
	padding-left: 8px !important;
}

.value-row #negative {
	color: Crimson !important;
	padding-left: 8px !important;
}

.value-row #neutral {
	padding-left: 8px !important;
}

.value-row-contents {
}

.alert-type-search-container {
	margin-bottom: 16px;
}

.alert-type-label {
	font-size: 14px;
}

.alert-type-formControl {
	color: #858796 !important;
	padding: 6px 16px !important;
	width: 100% !important;
	font-size: 13px !important;
	border: none !important;
	border-width: 0 !important;
}

.alert-type-formControl::placeholder {
	color: #858796 !important;
}

#mentionedIn-true {
	color: LimeGreen;
}

.mentionedIn-icon {
	font-size: 16px;
}

.mentionedIn-icon#true {
	color: LimeGreen;
}

.mentionedIn-icon#ongoing {
	color: goldenRod;
}

.mentionedIn-header {
	width: 100%;
	justify-content: center;
	margin-top: 12px;
	text-decoration: underline;
}

.order-details-row {
	justify-content: space-between !important;
	font-size: 14px;
	margin-bottom: 4px;
}

.order-details-row:not(:last-child) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.order-details-row .key {
	font-weight: bold;
	width: max-content;
}

.order-details-row .value {
	width: max-content !important;
	max-width: 300px;
	text-align: right;
}

.order-details-row .value#Bid {
	color: LimeGreen;
	font-weight: bold;
}

.order-details-row .value#Offer {
	color: Crimson;
	font-weight: bold;
}

.table-header {
	/* overflow-x: hidden !important; */
	/* width: 100%; */
	/* background: white; */
}

.table-body {
}

.history-audit-search-bar-row {
	display: flex;
	justify-content: space-between;
	height: max-content;
}

.history-audit-search-bar-row button {
	height: 32px;
}

.history-audit-search-bar {
	display: flex;
	align-items: center;
	margin-bottom: 4px;
}

.history-audit-search-label {
	width: 50px;
}

.scrollable-wrapper {
	height: 100%;
	max-height: 1000px;
	overflow-y: scroll;
	overflow-x: scroll;
}

.cm-investigate-top-row {
	margin-bottom: 32px;
}

.cm-investigate-content-row {
	justify-content: space-between;
}

.cm-investigate-container {
	/* min-width: 300px; */
	/* width: 33%; */
	width: 100%;
	/* max-width: 1000px; */
}

.cm-investigate-content {
	background: rgba(0, 0, 0, 0.1);
	height: 300px;
	width: 100%;
	margin-bottom: 16px;
	/* overflow: hidden; */
}

.cm-investigate-content .table-responsive {
	height: 100%;
}

.cm-investigate-container .search-bar {
	margin-bottom: 8px;
}

.cm-fills-content {
	margin-top: 39px;
}

.cm-table {
	font-size: 11px;
	margin: 0 !important;
}

.cm-no-content {
	color: #858796;
	border-bottom: 0 !important;
}

.cm-table thead {
}

.cm-table th {
	padding: 12px !important;
}

.cm-table td {
	padding: 12px !important;
}

.cm-reload-button {
	position: absolute;
	right: 0;
}

.searchable-td:hover {
	text-decoration: underline;
	cursor: pointer;
	color: blue;
}

.error-reason {
	text-align: center;
}

.cm-table-title {
	font-weight: bold;
}

.cm-add-attachment-row {
	justify-content: flex-end;
	margin-bottom: 8px;
}

.cm-modal-row {
	margin-bottom: 16px;
}

.cm-attachments-row {
	margin-bottom: 16px;
}

.cm-attachment {
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	width: 150px;
	height: 150px;
	position: relative;
	margin-right: 16px;
	margin-bottom: 16px;
}

.cm-attachment a {
	height: 100%;
	width: 100%;
	text-align: center;
	align-items: center;
	justify-content: center;
	display: flex;
}

.cm-attachment-desc {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.cm-attachment-short-desc {
	position: absolute;
	justify-content: center;
	bottom: 0;
	height: max-content !important;
	width: 100%;
	padding: 4px;
}

.cm-add-attachment-button {
	width: 122px;
	height: 32px;
	padding: 0 !important;
}

.cm-add-attachment-button .common-grey-spinner {
	height: 28px;
	width: 28px;
}

.cm-attachment-icon-row {
	/* height: 125px; */
	/* width: 100%; */
	/* min-height: 75px; */
	justify-content: center;
	align-items: center;
	font-size: 32px;
}

.cm-attachment-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.cm-attachment-desc-row {
	padding: 4px;
	overflow-y: scroll;
	margin-bottom: 20px;
	text-align: left;
}

.cm-attachment-details-description {
	font-size: 14px;
	width: 100%;
}

.attachment-info-icon {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0 !important;
}

.remove-attachment {
	position: absolute;
	bottom: 0;
	width: 100%;
	align-items: center;
	text-align: center;
	color: #4e73df;
}

.remove-attachment:hover {
	color: #2e59d9;
	cursor: pointer;
	text-decoration: underline;
}

.cm-no-attachments {
	justify-content: center;
	height: 48px;
	align-items: center;
	display: flex;
	width: 100%;
}

.no-access-box {
	color: #858796;
	justify-content: center;
	display: flex;
	align-items: center;
	font-size: 20px;
	position: relative;
}

.no-access-logout {
	position: absolute;
	bottom: 8px;
	font-size: 16px;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-title {
	width: 100%;
	word-break: normal;
	/* word-break: break-all !important; */
	/* text-overflow: clip; */
	/* text-overflow: ellipsis; */
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-collapse {
	position: absolute !important;
	left: 100px;
	background: black !important;
	width: max-content;
	transform: translate(100px, 0px) !important;
}

.admin-sidebar#collapsed-sidebar .dropdown-toggle::after {
	display: none;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-collapse ul li {
	padding: 8px 16px;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-collapse ul li:hover {
	background: rgba(255, 255, 255, 0.3);
	border-radius: 3px;
}

.admin-content-container {
	padding: 24px !important;
	position: relative;
}

/* PATTERN DETECTION ALERTS */

.admin-linegraph-tooltip {
	width: max-content;
	background: white;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 8px;
	border-radius: 3px;
	box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
	font-size: 12px;
}

.pre-trade-alerts-tooltip {
	position: absolute;
	right: 0;
}

.admin-tooltip-date-row {
	margin-bottom: 8px;
}

.admin-tooltip-date {
	margin-left: 8px;
}

.tooltip-row {
	align-items: center;
	justify-content: space-between;
}

.tooltip-front-loader {
	/* width: max-content !important; */
	/* background: orange; */
	display: flex;
	align-items: center;
}

.tooltip-row b {
	/* margin-left: 8px; */
}

.tooltip-color-square {
	height: 12px;
	width: 12px;
	/* background: red; */
	border-radius: 25px;
	margin-right: 8px;
}

.no-users-found-row {
	/* padding-bottom: 16px; */
	width: 100%;
	justify-content: center;
	color: #858796;
}

.cm-table-header {
	width: max-content;
	/* max-width: max-content !important; */
}

.cm-see-more-button {
}

.cm-date-created-row {
	width: 100%;
	min-width: 120px !important;
	/* font-size: 11px; */
	justify-content: space-between;
}

.cm-case-data-row {
	width: max-content;
}

.test {
	height: 1000px;
}

.green-text {
	color: limeGreen;
}

.red-text {
	color: Crimson;
}

.refno-link {
	width: max-content;
}

.refno-link:hover {
	cursor: pointer;
	color: blue !important;
	text-decoration: underline !important;
}

.refno-links-container {
	display: flex;
	flex-direction: column;
}

.refresh-button {
	position: absolute;
	right: 0;
	top: 8px;
}

.td-col {
	display: flex;
	flex-direction: column;
}

.order-trade-user-search-bar {
	height: 37px !important;
}

.overview-box {
	border: 1px solid rgba(0, 0, 0, 0.1);
	width: 300px;
	float: left;
}

.overview-box b {
	margin-right: 4px;
}

.overview-box .section-row {
	padding: 4px;
	height: max-content;
}

.overview-box .section-row:not(:last-child) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.overview-loading-box {
	padding: 16px;
	justify-content: center;
	width: 100%;
}

.ftb-column {
	/* height: max-content !important; */
}

.mdr-content-row {
	height: max-content !important;
	padding: 8px 0;
}

.mdr-chart-container {
	width: 100%;
}

.color-td#blue {
	/* border: 2px solid rgba(0, 0, 200); */
	background: royalBlue;
	color: white;
	border: 1px solid navy;
}

.color-td#green {
	background: ForestGreen;
	color: white;
	/* color: black; */
	border: 1px solid darkgreen;
}

.color-td#red {
	background: FireBrick;
	border: 1px solid maroon;
	color: white;
}

.overview-box-container {
	width: 100%;
	/* overflow-x: scroll; */
}

.overview-box-row {
	justify-content: space-between;
	height: max-content !important;
}

.overview-box-row:nth-child(even) {
	background: rgba(0, 0, 0, 0.05);
}

.order-book-table {
	margin: 0 !important;
	table-layout: fixed;
	cursor: default;
	/* width: 100% !important; */
	min-width: max-content !important;
}

.order-book-table thead {
	color: #858796;
}

.order-book-table th {
	text-align: center;
}

.order-book-table td {
	/* width: max-content !important; */
	/* min-width: 33%; */
}

.order-book-table .cost-td {
	text-align: center;
	color: white;
	border: 1px solid rgba(0, 0, 0, 0.6);
	background: rgba(0, 0, 0, 0.5);
	position: relative;
}

.order-book-table .ask-volume-td {
	text-align: left;
}

.order-book-table .buy-volume-td {
	text-align: right;
	/* align-items: flex-end; */
}

.order-book-table tr {
	position: relative;
	width: max-content !important;
	/* width: max-content; */
}

.order-book-table tr:hover .cost-td {
	background: rgba(0, 0, 0, 0.6);
}

.order-book-table tr:hover .reveal-button {
	display: block;
}

.order-book-table tr:hover #green {
	background: darkGreen;
}

.order-book-table tr:hover #red {
	background: maroon;
}

.order-book-table .cost-td .reveal-button {
	display: none;
}

.order-book-table .cost-td .reveal-button {
  padding: 0;
  position: absolute;
  right: 4px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  color: #858796;
  cursor: pointer;
}

.order-book-table .cost-td .reveal-button:hover {
	background: white;
	color: black;
}

.order-book-table .refno {
	background: rgba(0, 0, 0, 0.3);
	margin: 0 2px 2px 0;
	border-radius: 3px;
	font-size: 10px;
	text-align: center;
}

.order-book-table .refno:hover {
	cursor: pointer;
	text-decoration: underline;
}

.in-production-table {
	text-align: center;
	color: #858796;
	padding: 16px;
	height: 100%;
}
.password-icon {
	position: absolute;
	cursor: pointer;
	right: 2.5em;
	margin-top: -1.75rem;
}
